import common from '@/base/common.js'
import http from '@/utils/require.js'
// const baseUrl = '/apis-psychology/'
const baseUrl = process.env.NODE_ENV != 'development' ? common.certifiedJkmc : ''

/**
 * 学校大屏数据
 * @method: GET
 * @param: params 参数
 **/
export const screenQueryList = (params) => {
  return http.get(`${baseUrl}apis-psychology/screen/school/queryList`, {
    params,
  })
}

/**
 * 学校大屏数据分页
 * @method: GET
 * @param: params 参数
 **/
export const screenQueryPage = (params) => {
  return http.get(`${baseUrl}apis-psychology/screen/school/queryPage`, {
    params,
  })
}

/**
 * 学校大屏数据分页新(左下)
 * @method: GET
 * @param: params 参数
 **/
export const screenQueryPageNew = (params) => {
  return http.get(`${baseUrl}apis-psychology/screen/school/queryClassTj`, {
    params,
  })
}

/**
 * 获取登录用户权限
 * @method: post
 * @param: data 参数
 **/
export const permsCurrent = (data) => {
  return http.post(`${baseUrl}apis-rbac/role/perms/current`, data)
}
/**
 * 获取照片
 * @method: post
 * @param: data 参数
 **/
export const fileGetByPathList = (data) => {
  return http.post(`${baseUrl}apis-third/file/listByPath`, data)
}
