<template>
  <div>
    <el-container class="project-box">
      <div class="container_box">
        <Sidebar></Sidebar>
        <el-container class="rightBox">
          <el-header class="flex justify_between header-box">
            <Logo :title="title" />
            <headerNav />
            <div class="flex">
              <Account ref="amdin" @remove="remove" @updateInfo="updateInfo" />
            </div>
          </el-header>
          <el-main :class="{ hasBg: hasBg }">
            <div id="water">
              <canvas id="canvas"></canvas>
            </div>
            <keep-alive exclude="CustomScalePage,CustomEditScale">
              <router-view :key="$route.fullPath" style="position: relative" />
            </keep-alive>
          </el-main>
        </el-container>
      </div>
    </el-container>
  </div>
</template>
<script>
import * as API from '@/api/app.js'
import Logo from '@/components/logo/logo.vue'
import Account from '@/components/account/index.vue'
import Sidebar from './sidebarMenu.vue'
import { getUserLogin } from '@/utils/auth'
import headerNav from '@/components/layout/headerNav.vue'
export default {
  components: { Logo, Account, headerNav, Sidebar },
  props: {
    title: {
      type: String,
    },
  },
  data () {
    return {
      userInfo: getUserLogin(),
      identityId: 1,
    }
  },
  mounted () {
    console.log('当前路由信息:', this.$route);
    this.getWater()
  },
  computed: {
    hasBg () {
      // 背景图页面不留白，在这配置
      return ['/data/home'].indexOf(this.$route.fullPath) !== -1
    },
  },
  methods: {
    updateInfo (value) {
      this.userInfo = value
    },
    remove (userInfo) {
      this.userInfo = userInfo
    },
    getWater () {
      console.log(this.userInfo, 999);

      const canvas = document.getElementById("canvas");//获取canvas
      canvas.width = 300;//设置画布宽度
      canvas.height = 100;//设置画布高度
      canvas.style.display = "none";//隐藏画布本身
      const ctx = canvas.getContext("2d");//获取画笔
      ctx.font = "18px Arial";
      ctx.fillStyle = "rgba(230, 230, 230, 1)";//设置文字颜色及透明度
      ctx.rotate(-0.35);//设置文字旋转角度
      ctx.fillText(this.userInfo.nickname, canvas.width / 6, canvas.height / 2);//设置显示文字内容
      const img = canvas.toDataURL("image/png");//参数默认为 image/png,可以是其他image/jpeg等，该方法返回值是一个url，是base64组成的图片的源数据、可以直接赋值给图片的src属性
      const style = `background-image:url(${img});`;//定义样式
      water.setAttribute("style", style);//给要添加水印的元素设置样式
    }
  },
}
</script>
<style lang="scss" scoped>
.project-box {
  height: 100vh;
}
.container_box {
  display: flex;
  width: 100%;
  min-height: calc(100% - 60px);
}
.logo-box {
  min-width: 230x;
}
.header-box {
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #e5e6eb;
}

.rightBox {
  background: #f9fafb;
}
.content_box {
  margin-top: 60px;
}

.hasBg {
  padding: 0;
}

#water {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 0;
}
</style>
