import common from '@/base/common.js'
import http from '@/utils/require.js'
// const baseUrl = '/apis-psychology/'
const baseUrl = process.env.NODE_ENV != 'development' ? common.certifiedJkmc : ''

// 学生档案列表页 start
/**
 * 学生情绪列表
 * @method: GET
 * @param {String} pageNum
 * @param {String} pageSize
 * @param {String} classOrgId 班级组织机构id
 * @param {String} schoolOrgId 学校组织机构id
 * @param {String} campusOrgId 校区组织机构id
 * @param {String} gradeOrgId 年级组织机构id
 * @param {String} name 学生名称
 **/
export const emotionPageList = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/emotionPage`, {
    params,
  })
}

/**
 * 学校校区年级班级下拉框数据
 * @method: GET
 * @param {String} orgType 查询学校 node_school 查询校区 node_campus 查询年级 node_grade 查询班级 node_class
 * @param {String} orgParentId 上级机构id（非必填
 **/
export const getDropList = (params) => {
  return http.get(`${baseUrl}apis-rbac/org/tree/getDropList`, {
    params,
  })
}

/**
 * 学生下拉框数据
 * @method: GET
 
 * @param {String} orgId 上级机构id（非必填
 **/
export const getStudentByOrgId = (params) => {
  return http.get(`${baseUrl}apis-psychology/dataStatistics/queryAiChatReport/student`, {
    params,
  })
}

// 学生档案列表页 end

// 学生信息详情 start
/**
 * 获取登录用户权限
 * @method: GET
 * @param {string} userId
 **/
export const getEmotionInfo = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/emotionInfo`, {
    params,
  })
}

// 学生信息详情 end

// 预警干预详情列表页 start

/**
 * 预警干预详情
 * @method: GET
 * @param {string} userId
 **/
export const warningPage = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/early/warning/intervene/page`, {
    params,
  })
}

/**
 * 预警干预详情列表
 * @method: GET
 * @param {string} userId
 **/
export const warningPageList = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/epicycle/early/warning/intervene/page`, {
    params,
  })
}

/**
 * 预警下拉数据
 * @method: GET
 * @param
 **/
export const getWarningItem = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/code/early/warningItem`, {
    params,
  })
}

/**
 * 干预下拉数据
 * @method: GET
 * @param
 **/
export const getInterveneItem = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/code/intervene`, {
    params,
  })
}

/**
 * 统计预警、干预、测评、心情等次数
 * @method: GET
 * @param
 **/
export const getInterveneCount = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/get/stu/early/warning/intervene/count`, {
    params,
  })
}
/**
 * 预警干预列表
 * @method: GET
 * @param
 **/
export const getIntervenePage = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/early/warning/intervene/page`, {
    params,
  })
}

/**
 * 最新量表成绩详情列表（前两次)
 * @method: GET
 * @param
 **/
export const getNewestScaleInfoNew = (params) => {
  return http.get(`${baseUrl}apis-psychology/screen/user/newest/scale/info`, {
    params,
  })
}
/**
 * 根据用户查询量表记录列表
 * @method: GET
 * @param {string}
 **/
export const getScaleInfoListNew = (params) => {
  return http.get(`${baseUrl}apis-psychology/screen/user/scale/info/list`, {
    params,
  })
}

/**
 * 最新量表成绩详情列表（前两次)
 * @method: GET
 * @param
 **/
export const getNewestScaleInfo = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/newest/scale/info`, {
    params,
  })
}
/**
 * 1-3年级测评（MHRSP量表）量表预警
 * @method: GET
 * @param
 **/
export const getItemStatusMhrsp = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/mhrsp/${id}`, {})
}
/**
 * 4-9年级测评（MHT量表）量表预警
 * @method: GET
 * @param
 **/
export const getItemStatusMht = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/mht/${id}`, {})
}
/**
 * phq量表预警
 * @method: GET
 * @param
 **/
export const getItemStatusPHQ = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/phq/${id}`, {})
}
/**
 * kte量表预警
 * @method: GET
 * @param
 **/
export const getItemStatusKTE = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/kte/${id}`, {})
}
/**
 * 情绪预警
 * @method: GET
 * @param
 **/
export const getItemStatusQxsc = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/qxsc/${id}`, {})
}
/**
 * 考勤预警
 * @method: GET
 * @param
 **/
export const getItemStatusKqsc = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/kqsc/${id}`, {})
}
/**
 * 家长日常动态反馈预警
 * @method: GET
 * @param
 **/
export const getItemStatusFmrcdtgc = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/fmrcdtgc/${id}`, {})
}

/**
 * 访谈干预
 * @method: GET
 * @param {string} id
 **/
export const getReviewService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/ft/${params}`)
}

/**
 * 班主任家访详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewBzrService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/bzrjf/${params}`)
}
/**
 * 谈心谈话详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewTxthService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/txth/${params}`)
}
/**
 * 辅导谈话详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewFdthService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/gygz/fdth/${params}`)
}

/**
 * 多方会谈详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewDfhtService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/gygz/dfht/${params}`)
}
/**
 * 突发事件详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewTfsjService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/gygz/tfsj/${params}`)
}

/**
 * 在线转介详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewZxzjService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/gygz/zxzj/${params}`)
}

/**
 * 访谈流水详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewFtlsService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/ft/ftls/${params}`)
}

/**
 * 复学会谈详情
 * @method: GET
 * @param {string} id
 **/
export const getReviewFxhtService = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/review/gygz/fxht/${params}`)
}

/**
 * 查询本学期家访列表
 * @method: GET
 * @param {string}
 **/
export const getHomevisitThisSemester = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/home/visits/this/semester`, {
    params,
  })
}
/**
 * 根据日期查询最近7天情绪列表
 * @method: GET
 * @param {string}
 **/
export const getLatestSevenDaysMood = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/last/seven/days/mood`, {
    params,
  })
}
/**
 * 全员家访详情
 * @method: GET
 * @param
 **/
export const getSurveyRecord = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/survey/record${id}`, {})
}
/**
 * 根据用户查询量表记录列表
 * @method: GET
 * @param {string}
 **/
export const getScaleInfoList = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/scale/info/list`, {
    params,
  })
}
/**
 * 1-3年级测评（MHRSP量表）量表预警
 * @method: GET
 * @param
 **/
export const getMhrsp = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/mhrsp/${id}`, {})
}
/**
 * 4-9年级测评（MHT量表）量表预警
 * @method: GET
 * @param
 **/
export const getMht = (id) => {
  return http.get(`${baseUrl}apis-psychology/analysis/itemStatus/mht/${id}`, {})
}
/**
 * 获取学生基本信息，包含所属班级，年级信息
 * @method: GET
 * @param {string}
 **/
export const getStudentInfo = (params) => {
  return http.get(`${baseUrl}apis-psychology/common/getStudentInfo`, {
    params,
  })
}
/**
 * 获取图片
 * @method: post
 * @param: data 参数
 **/
export const fileGetByPath = (params) => {
  return http.get(`${baseUrl}apis-third/file/getByPath`, { params })
}

// 预警干预详情列表页 end

// 获取学生心理曲线

export const getStudentLine = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/Warning/graph/xs/level`, { params })
}

/**
 * 教师量表报告
 * @method: GET
 * @param
 **/
export const getItemStatusTeacher = (data) => {
  return http.post(`${baseUrl}apis-psychology/analysis/itemStatus/cp/report`, data)
}

/**
 * 教师量表报告
 * @method: GET
 * @param
 **/
export const getItemStatusTeacherNew = (data) => {
  return http.post(`${baseUrl}apis-psychology/screen/user/cp/report`, data)
}
/**
 * 常模量表报告
 * @method: GET
 * @param
 **/
export const shangHaiNormReport = (data) => {
  return http.post(`${baseUrl}apis-psychology/analysis/itemStatus/cp/normReport`, data)
}

/**
 * 干预筛查全部详情列表
 * @method: GET
 * @param {string} userId
 **/
export const warningPageListAll = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/warning/scsxPage`, {
    params,
  })
}

/**
 * 获取学生智能干预数据报告
 * @method: GET
 * @param {string} warnId
 **/
export const intervenePageFile = (params) => {
  return http.get(`${baseUrl}apis-psychology/process/rule/zngy`, {
    params,
  })
}

/**
 * 获取学生智能干预数据报告
 * @method: GET
 * @param {string} warnId
 **/
export const interviewPageFile = (params) => {
  return http.get(`${baseUrl}apis-psychology/process/rule/djft`, {
    params,
  })
}

/**
 * 获取学生八维心理健康指数 仪表盘
 * @method: GET
 * @param {string} id
 **/
export const getStudentsOctuple = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/warning/gauge`, {
    params,
  })
}

/**
 * 获取八维心理健康指数 雷达图
 * @method: GET
 * @param {string} id
 **/
export const getStudentsOctupleRadar = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/warning/radarList`, {
    params,
  })
}

/**
 * 获取八维心理健康指数 报告文案
 * @method: GET
 * @param {string} id
 **/
export const getStudentsOctupleAIReport = (params) => {
  return http.get(`${baseUrl}apis-psychology/analysis/warning/ai/gen`, {
    params,
  })
}

/**
 * 获取学校情绪日志列表
 * @method: POST
 **/
export const getStudentsEmotionList = (data) => {
  return http.post(`${baseUrl}apis-psychology/studentEmotionData/getStudentMoodList`, data)
}

/**
 * 获取学年列表
 * @method: GET
 **/
export const getSchoolYearList = (params) => {
  return http.get(`${baseUrl}apis-psychology/common/getXuenianList`, {
    params,
  })
}

/**
 * 获取学生学年学期的情绪数据
 * @method: POST
 **/
export const getStudentsEmotionByYear = (data) => {
  return http.post(`${baseUrl}apis-psychology/studentEmotionData/getMonthQxxxDataList`, data)
}

/**
 * 查学生档案信息
 * @method: POST
 **/
export const queryBaseInfo = (data) => {
  return http.post(`${baseUrl}apis-psychology-audit/studentArchives/queryBaseInfo`, data)
}

/**
 * 查学生学年学期
 * @method: POST
 **/
export const queryXnxqHistory = (data) => {
  return http.post(`${baseUrl}apis-psychology-audit/studentArchives/queryXnxqHistory`, data)
}
