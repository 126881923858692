/**
 * 状态管理模式
 */
import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user.js'
import Scale from './modules/scale.js'

import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations, //同步触发状态变更
  modules: {
    User,
    Scale,
  },
})
export default store
