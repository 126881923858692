import common from '@/base/common.js'
import axios from '@/utils/require.js'

const base = process.env.NODE_ENV == 'production' ? '/apis-app/' : '/apis-app/'
const base2 = process.env.NODE_ENV == 'production' ? '' : ''
const rbacUrl = process.env.NODE_ENV == 'production' ? '/apis-rbac/' : '/apis-rbac/'
const portalUrl = process.env.NODE_ENV == 'production' ? '/apis-portal/' : '/apis-portal/'
const baseUserUrl = process.env.NODE_ENV == 'production' ? '/apis-user/' : '/apis-user/'
const baseAuthUrl = process.env.NODE_ENV == 'production' ? '/apis-authz/' : '/apis-authz/'

/****************************应用管理****************************************/

export default {
  // 查询应用级别列表(新)
  AppLevelList(params) {
    return axios({
      url: `${base}app/level/list`,
      method: 'get',
      params,
    })
  },
  // 获取我的应用
  getMyApplications(params) {
    return axios({
      url: `${base}app/myAppRole`,
      method: 'get',
      params,
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },
  // 用户申请,点击进入应用
  applyApp(params) {
    return axios({
      url: `${base}appUser/applyApp`,
      method: 'get',
      params,
    })
  },
  // 用户申请,点击进入应用(新版)
  applyUserApp(params) {
    return axios({
      url: `${base}app/user/apply`,
      method: 'get',
      params,
    })
  },

  // 查询应用级别列表
  queryAppLevelList(params) {
    return axios({
      url: `${base}app/level/list`,
      method: 'get',
      params,
    })
  },

  //我申请的审核中的应用
  myPendingApp(params) {
    return axios({
      url: `${base}app/apply/pendingList`,
      method: 'get',
      params,
    })
  },
  //我申请的审核中的应用（新）
  AppApplyPendingList(params) {
    return axios({
      url: `${base}app/apply/pendingList`,
      method: 'get',
      params,
    })
  },

  //首页应用列表(新版)
  getIndexApp(params) {
    return axios({
      url: `${base}app/indexApp`,
      method: 'get',
      params,
    })
  },
  queryApplyInfo(params) {
    return axios({
      url: `${base}appUser/queryApplyInfo`,
      method: 'get',
      params,
    })
  },
  // 获取用户信息（新）
  AppUserApplyInfo(params) {
    return axios({
      url: `${base}app/user/applyInfo`,
      method: 'get',
      params,
    })
  },
  // 获取当前角色权限
  getCurrentPermsByRole(data) {
    return axios({
      url: `${rbacUrl}role/perms/current`,
      method: 'post',
      data,
    })
  },

  // 记录点击
  appClickRecord(data) {
    return axios({
      url: `${base}app/click`,
      method: 'post',
      data,
    })
  },
  // 应用三方账号绑定
  appBindAccount(data) {
    return axios({
      url: `${base}app/account/bind`,
      method: 'post',
      data,
    })
  },
  // 基础数据管理应用(超级管理员/机构管理员进入门户呈现“基础数据管理应用”)
  getAppBaseManageService(params) {
    return axios({
      url: `${portalUrl}app/baseManage`,
      method: 'get',
      params,
    })
  },
  // 获取角色权限应用
  rolePermissionAppInfo(data) {
    return axios({
      url: `${base}app/account/app`,
      method: 'post',
      data,
    })
  },

  getUserRoleInfo(data) {
    return axios({
      url: `${base2}apis-psychology/common/userRoleInfo`,
      method: 'get',
      data,
    })
  },
  queryClassTeacherInfo(data) {
    return axios({
      url: `${base2}apis-psychology/common/queryClassTeacherInfo`,
      method: 'get',
      data,
    })
  },
  // 获取待办列表
  getPendingList(data) {
    return axios({
      url: `/apis-psychology/todo/list`,
      method: 'post',
      data,
    })
  },
  /**
   * 【待办】 改变任务状态
   * @method get
   * @param { Number } taskId id
   */
  changeDatacheckInfoStatus(data) {
    return axios({
      url: `/apis-checkoff/task/deliver/handler/update/status`,
      method: 'post',
      data,
    })
  },

  /**账号中心需求 start */
  // 是否机构管理员
  roleOrgIsOrgAdmin(params) {
    return axios({
      url: `${rbacUrl}role/org/user/isOrgAdmin`,
      method: 'get',
      params,
    })
  },
  // 是否超级管理员
  roleIsAdmin(params) {
    return axios({
      url: `${rbacUrl}role/org/user/isAdmin`,
      method: 'get',
      params,
    })
  },
  // 超级管理员查看解绑提示信息
  getUnBindInfo(params) {
    return axios({
      url: `${baseAuthUrl}auth/user/getUnBindInfo`,
      method: 'get',
      params,
    })
  },
  // 超级管理员解绑
  unBindInfo(params) {
    return axios({
      url: `${baseAuthUrl}auth/user/unBindAccount`,
      method: 'get',
      params,
    })
  },
  // 判断机构管理员是否绑定
  isBindingOrgAdmin(params) {
    return axios({
      url: `${baseAuthUrl}auth/user/isBindAccount`,
      method: 'get',
      params,
    })
  },
  // 获取绑定记录
  getBindLog(params) {
    return axios({
      url: `${baseAuthUrl}auth/user/getBindLog`,
      method: 'get',
      params,
    })
  },
  // 获取绑定记录
  getBindInfo(params) {
    return axios({
      url: `${baseAuthUrl}auth/user/getBindInfo`,
      method: 'get',
      params,
    })
  },

  // 绑定账号
  bindAccountService(data) {
    return axios({
      url: `${baseAuthUrl}auth/user/bindAccount`,
      method: 'post',
      data,
    })
  },
  // 移交账号
  transferAccount(data) {
    return axios({
      url: `${baseAuthUrl}auth/user/transferAccount`,
      method: 'post',
      data,
    })
  },
  getSchoolTree(params) {
    return axios({
      url: `${rbacUrl}org/tree/schoolTree`,
      method: 'get',
      params,
    })
  },
  // 学校机构树
  getSchoolTreeNew(params) {
    return axios({
      url: `${rbacUrl}org/tree/preview`,
      method: 'get',
      params,
    }).then((data) => {
      return data
    })
  },
  // 解绑账号
  unbindAccount(data) {
    return axios({
      url: `${baseAuthUrl}auth/user/unbindAccount`,
      method: 'post',
      data,
    })
  },
  // 获取机构管理员账号信息
  getOrgAdminInfo(params) {
    return axios({
      url: `${baseUserUrl}user/info/orgAdminAccount`,
      method: 'get',
      params,
    })
  },
  // 获取角色权限
  getRolePermissionInfo(params) {
    return axios({
      url: `${rbacUrl}user/identity/role/permission`,
      method: 'get',
      params,
    })
  },
  /**账号中心需求 end */
  // 根据path批量查询文件信息
  fileGetByPathList(params) {
    return axios({
      method: 'post',
      url: `/apis-third/file/listByPath`,
      data: {
        ...params,
      },
    })
  },
  // 图片上传功能
  fileUpload(data) {
    return axios({
      method: 'post',
      url: `/apis-third/file/upload/image`,
      data,
    })
  },
  // 获取信息
  analysisModulesById(id) {
    return axios.get(`/apis-psychology/analysis/modules/${id}`)
  },

  /**
   * 家访记录-批量文件打包下载-创建打包下载任务接口
   * @method: POST
   * @param {String} id 家访任务分页列表的ID
   **/
  batchCreateTask(data) {
    return axios({
      method: 'post',
      url: `/apis-psychology/survey/record/createTask`,
      data,
    })
  },
  /**
   * 重点关注-文件是否已经生成状态接口
   * @method: POST
   * @param {String} id 家访任务分页列表的ID
   **/
  queryFileStatus(data) {
    return axios({
      method: 'post',
      url: `/apis-psychology/analysis/review/ft/query`,
      data,
    })
  },
  /**
   * 重点关注-调用接口下载接口
   * @method: POST
   * @param {String} id 任务分页列表的ID
   **/
  downloadAttentionFileTask(data) {
    return axios({
      method: 'post',
      url: `/apis-psychology/analysis/review/ft/download`,
      data,
    })
  },
  /**
   * 重点关注访谈定级-批量文件打包下载
   * @method: POST
   **/
  batchCreateAttentionTask(data) {
    return axios({
      method: 'post',
      url: `/apis-psychology/analysis/review/ft/createTask`,
      data,
    })
  },
  /**
   * 回访干预-批量文件打包下载-学校打包
   * @method: POST
   **/
  createInterveneTaskAll(data) {
    return axios({
      method: 'post',
      url: `/apis-psychology/analysis/review/gygz/createGroupTask`,
      data,
    })
  },

  /**
   * 回访干预-批量文件打包下载-学生打包
   * @method: POST
   **/
  createInterveneTaskStudent(data) {
    return axios({
      method: 'post',
      url: `/apis-psychology/analysis/review/gygz/createTask`,
      data,
    })
  },

  /**下载中心 */
  /**
   * 文件下载中心分页列表接口（当前登录人创建的下载任务列表）
   * @method: GET
   * @param {String} pageNum
   * @param {String} pageSize
   * @param {String} classOrgId 班级组织机构id
   * @param {String} schoolOrgId 学校组织机构id
   * @param {String} campusOrgId 校区组织机构id
   * @param {String} gradeOrgId 年级组织机构id
   * @param {String} name 学生名称
   **/
  fileCenterPageList(params) {
    return axios({
      url: `/apis-psychology/analysis/fileCenter/page`,
      method: 'get',
      params,
    })
  },

  /**
   * 查询是否可下载
   * @method: POST
   * @param {String} id 下载任务ID
   **/
  fileCenterQuery(data) {
    return axios({
      url: `/apis-psychology/analysis/fileCenter/query`,
      method: 'post',
      data,
    })
  },
  /**
   * 查询下载文件信息
   * @method: POST
   * @param {String} id 下载任务ID
   **/
  fileCenterInfoQuery(data) {
    return axios({
      url: `/apis-psychology/analysis/fileCenter/file/info`,
      method: 'post',
      data,
    })
  },
  /**
   * 下载中心-下载文件
   * @method: POST
   * @param {String} id 下载任务ID
   **/
  fileDownload(data) {
    return axios({
      url: `/apis-psychology/analysis/fileCenter/download`,
      method: 'post',
      data,
    })
  },
  /**
   * 下载中心-取消下载任务
   * @method: POST
   * @param {String} id 下载任务ID
   **/
  cancelFileDownload(data) {
    return axios({
      url: `/apis-psychology/analysis/fileCenter/cancel`,
      method: 'post',
      data,
    })
  },
  /**
   * 下载中心-删除下载任务
   * @method: POST
   * @param {String} id 下载任务ID
   **/
  deleteFileDownload(data) {
    return axios({
      url: `/apis-psychology/analysis/fileCenter/delete`,
      method: 'post',
      data,
    })
  },

  /**
   * 省平台数据上行-列表
   * @method: POST
   * @param {String} id 下载任务ID
   **/
  syncUpList(data) {
    return axios({
      url: `/apis-psychology/spt/sync/up/list`,
      method: 'post',
      data,
    })
  },
  /**
   * 省平台数据上行-上报
   * @method: POST
   * @param {String} userId 用户ID
   **/
  syncUp(data) {
    return axios({
      url: `/apis-psychology/spt/sync/up`,
      method: 'post',
      data,
    })
  },
  /**
   * 省平台数据上行-配置信息
   * @method: get
   * @param {String}
   **/
  syncUpConfigInfo(data) {
    return axios({
      url: `/apis-psychology/spt/sync/config/info`,
      method: 'get',
      data,
    })
  },
  /**
   * 省平台数据上行-配置保存或者更新
   * @method: POST
   * @param {String}
   **/
  saveOrUpdate(data) {
    return axios({
      url: `/apis-psychology/spt/sync/config/saveOrUpdate`,
      method: 'post',
      data,
    })
  },
  /**
   * 省平台数据上行-日志列表
   * @method: POST
   * @param {String}
   **/
  syncUpDetailList(data) {
    return axios({
      url: `/apis-psychology/spt/sync/up/detail/list`,
      method: 'post',
      data,
    })
  },
  /**
   * 省平台数据上行-日志列表
   * @method: POST
   * @param {String}
   **/
  modifyStudentInfo(data) {
    return axios({
      url: `/apis-psychology/spt/sync/modify/student/info`,
      method: 'post',
      data,
    })
  },
  /**
   * 省平台数据上行-日志列表重试
   * @method: POST
   * @param {String}
   **/
  syncUpRetry(data) {
    return axios({
      url: `/apis-psychology/spt/sync/up/retry`,
      method: 'post',
      data,
    })
  },

  /**
   * 安全预警-分页列表
   * @method: POST
   * @param {String}
   **/
  getWarningPageList(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/page`,
      method: 'post',
      data,
    })
  },

  /**
   * 安全预警-分页列表
   * @method: POST
   * @param {String}
   **/
  getWarningPageListNew(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/anomalyDetection/page`,
      method: 'get',
      params,
    })
  },

  /**
   * 安全预警-新增数据统计-total
   * @method: get
   * @param {String}
   **/
  getWarningTotalCount(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/statistics/count`,
      method: 'post',
      data,
    })
  },

  /**
   * 安全预警-获取学生等级信息
   * @method: get
   * @param {String}
   **/
  getStudentLevelInfo(params) {
    return axios({
      url: `/apis-psychology/user/status/waningInfo`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-新增数据统计
   * @method: get
   * @param {String}
   **/
  getWarningPageStatistics(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/statistics/new`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-新增数据统计
   * @method: get
   * @param {String}
   **/
  getWarningStatisticsNum(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/statistics/func/num`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警-数据统计图日期分组
   * @method: get
   * @param {String}
   **/
  getWarningPageEchartsDay(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/statistics/echarts/day`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-数据统计图类型分组
   * @method: get
   * @param {String}
   **/
  getWarningPageEchartsFunc(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/statistics/echarts/func`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-摄像头列表
   * @method: get
   * @param {String}
   **/
  getWarningPageCameraList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/camera/list`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-预警类型
   * @method: get
   * @param {String}
   **/
  getWarningTypeList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/func/type`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-预警类型
   * @method: get
   * @param {String}
   **/
  getByRelationId(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/getByRelationId`,
      method: 'get',
      params,
    })
  },

  /**
   * 安全预警-预警类型
   * @method: get
   * @param {String}
   **/
  getByRelationIdNew(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/anomalyDetection/itemList`,
      method: 'get',
      params,
    })
  },

  /**
   * 安全预警-移交数据给管理员
   * @method: get
   * @param {String}
   **/
  handoverWarningStudent(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/handover`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-处理数据
   * @method: POST
   * @param {String}
   **/
  handlingWarning(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/handling`,
      method: 'post',
      data,
    })
  },

  /**
   * 安全预警-处理数据
   * @method: POST
   * @param {String}
   **/
  handlingWarningNew(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/anomalyDetection/solve`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警-处理数据
   * @method: get
   * @param {String} orgId 机构id
   * @param {String} xm 名称
   **/
  searchForWarning(params) {
    return axios({
      url: `/apis-psychology/user/forWarning`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警-重点人员查询
   * @method: post
   * @param {String} orgId 机构id
   * @param {String} xm 名称
   **/
  searchUserForWarning(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/list`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警-重点人员查询
   * @method: post
   * @param {String} orgId 机构id
   * @param {String} xm 名称
   **/
  userForWarningAdd(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/add`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警-移除重点人员
   * @method: get
   * @param {String} id 用户id
   **/
  removeWarningUser(params) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/remove`,
      method: 'get',
      params,
    })
  },
  /**
   * 安全预警轨迹查询-人员轨迹查询
   * @method: post
   * @param {String} orgId 机构id
   * @param {String} startTimeLong 开始时间
   * @param {String} endTimeLong 结束时间
   * @param {String} userId 用户id
   * @param {String} sortType 排序
   * @param {String} wearSchoolUniform 是否穿校服
   *
   **/
  userForWarningSearch(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/ecology/search`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警轨迹查询-人员轨迹查询高级查询
   * @method: post
   * @param {String} orgId 机构id
   * @param {String} startTimeLong 开始时间
   * @param {String} endTimeLong 结束时间
   * @param {String} sortType 排序
   * @param {String} threshold 相似度
   * @param {Array} trackIds 检索id
   * @param {String} wearSchoolUniform 是否穿校服
   *
   **/
  userForWarningSeniorSearch(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/ecology/track/search`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警轨迹查询-录像（拉流）
   * @method: post
   * @param {String} orgId 机构id
   * @param {String} startTimeLong 开始时间
   * @param {String} endTimeLong 结束时间
   * @param {String} cameraId 摄像头id
   *
   **/
  playbackStart(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/video/playback/start`,
      method: 'post',
      data,
    })
  },
  /**
   * 安全预警轨迹查询-录像（拉流）
   * @method: post
   * @param {String} orgId 机构id
   * @param {String} streamId 流ID
   * @param {String} videoToken 视频Token
   * @param {String} cameraId 摄像头id
   *
   **/ playbackStop(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/user/video/playback/stop`,
      method: 'post',
      data,
    })
  },
  /**
   * 推送配置-分页列表
   * @method: GET
   * @param {String}
   **/
  getPushConfigPageList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/page`,
      method: 'get',
      params,
    })
  },
  /**
   * 推送配置-学校列表
   * @method: GET
   * @param {String}
   **/
  getPushConfigSchoolList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/orgList`,
      method: 'get',
      params,
    })
  },
  /**
   * 推送配置-行为代码列表
   * @method: GET
   * @param {String}
   **/
  getPushConfigTypeList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/funcTypeList`,
      method: 'get',
      params,
    })
  },
  /**
   * 推送配置-角色代码列表
   * @method: GET
   * @param {String}
   **/
  getPushConfigRoleList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/roleList`,
      method: 'get',
      params,
    })
  },
  /**
   * 推送配置-保存配置
   * @method: GET
   * @param {String}
   **/
  savePushConfig(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/save`,
      method: 'post',
      data,
    })
  },
  /**
   * 推送配置-发布配置
   * @method: GET
   * @param {String}
   **/
  publishPushConfig(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/setup`,
      method: 'post',
      data,
    })
  },
  /**
   * 推送配置-删除配置
   * @method: GET
   * @param {String}
   **/
  delPushConfig(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/configuration/delete`,
      method: 'post',
      data,
    })
  },

  /**
   * 重点人员日报-日报列表
   * @method: GET
   * @param {String}
   **/
  personnelDailyList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReport/page`,
      method: 'get',
      params,
    })
  },

  /**
   * 重点人员日报-单个日报详情
   * @method: GET
   * @param {String}
   **/
  getdailyInfo(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReport/list`,
      method: 'get',
      params,
    })
  },

  /**
   * 重点人员日报-单个日报详情
   * @method: GET
   * @param {String}
   **/
  saveDailyInfo(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReport/save`,
      method: 'post',
      data,
    })
  },

  /**
   * 日志配置-分页列表
   * @method: GET
   * @param {String}
   **/
  getDailyConfigPageList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReportConfiguration/page`,
      method: 'get',
      params,
    })
  },
  /**
   * 日志配置-发布配置
   * @method: GET
   * @param {String}
   **/
  publishDailyConfig(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReportConfiguration/setup`,
      method: 'post',
      data,
    })
  },

  /**
   * 日志配置-删除配置
   * @method: GET
   * @param {String}
   **/
  delDailyConfig(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReportConfiguration/delete`,
      method: 'post',
      data,
    })
  },
  /**
   * 日志配置-保存配置
   * @method: GET
   * @param {String}
   **/
  saveDailyConfig(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReportConfiguration/save`,
      method: 'post',
      data,
    })
  },
  /**
   * 日志配置-查询配置
   * @method: GET
   * @param {String}
   **/
  getDailyConfigById(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReportConfiguration/${params.id}`,
      method: 'get',
    })
  },

  /**
   * 查看学生的行为轨迹
   * @method: POST
   * @param {String}
   **/
  getStudentTrackList(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/dayReport/listCheck`,
      method: 'get',
      params,
    })
  },
  /**
   * 监控直播拉流
   * @method: POST
   * @param {String}
   **/
  controlMonitorOpen(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/camera/video/play/start`,
      method: 'post',
      data,
    })
  },
  /**
   * 监控直播关流
   * @method: POST
   * @param {String}
   **/
  controlMonitorStop(data) {
    return axios({
      url: `/apis-psychology-deepeleph/warning/camera/video/play/stop`,
      method: 'post',
      data,
    })
  },

  /**
   * 行为预警查询单条数据
   * @method: POST
   * @param {String}
   **/
  getWarningInfoNew(params) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/anomalyDetection/itemList`,
      method: 'get',
      params,
    })
  },

  /**
   * 标记数据
   * @method: get

   **/
  securityBehaviorAdd(data) {
    return axios({
      url: `/apis-psychology-deepeleph/deepeleph/securityBehavior/add`,
      method: 'post',
      data,
    })
  },
}
