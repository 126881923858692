import router from '../../router'

const scale = {
  state: {
    scaleInfo: JSON.parse(sessionStorage.getItem('scaleInfo')) || {},
  },

  mutations: {
    SET_SCALE: (state, code) => {
      state.scaleInfo = code
      window.sessionStorage.setItem('scaleInfo', JSON.stringify(code))
    },
  },

  actions: {
    saveScale({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            if (res.code == 200) {
              commit('SET_SCALE', res.data.token)
              resolve({
                code: 200,
                message: 'success',
                path: '/',
              })
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}

export default scale
