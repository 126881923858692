import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store'
import * as echarts from 'echarts'
import 'echarts-gl'
import FormCreate from '@form-create/element-ui'

import 'leaflet/dist/leaflet.css'

// 解决 Leaflet 默认图标路径问题
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.use(FormCreate)

Vue.use(VueRouter)

//全局引入事件机制
import eventLib from '@/utils/documentEvent.js'
window.$_EventLib = eventLib

import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import en from '@/lang/dongyang/en'
import cn from '@/lang/dongyang/cn'
//引入全局指令
import '@/directives/index'
import { setLang, getLang } from '@/utils/auth'
Vue.use(VueI18n)
let lang
if (getLang()) {
  lang = getLang()
} else {
  setLang('cn')
  lang = 'cn'
}
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import api from './api/api.js'
window.WAPI = api //功能人员无需关注当前接口是在哪里的，只需要调用全局API，弱化功能代码和调用API的耦合

const i18n = new VueI18n({
  locale: lang, // 语言标识
  messages: {
    en: {
      ...en,
      ...enLocale,
    },
    cn: {
      ...cn,
      ...zhLocale,
    },
  },
})
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
}) //多语言配置------结束
import router from './router'

// import './rem.js'
import './assets/css/global.scss'
import './assets/css/common.scss'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  i18n,
  store,
  router,
}).$mount('#app')

// ;
// (function (win) {
//   var bodyStyle = document.createElement('style')
//   bodyStyle.innerHTML = `body{width:1920px; height:1080px!important;overflow:hidden;}`
//   document.documentElement.firstElementChild.appendChild(bodyStyle)

//   function refreshScale() {
//     let baseWidth = document.documentElement.clientWidth;
//     let baseHeight = document.documentElement.clientHeight;
//     let appStyle = document.getElementById('app').style;
//     let scaleX = baseWidth / 1920
//     let scaleY = baseHeight / 1080
//     appStyle.transformOrigin = 'left top';
//     appStyle.transform = `scaleX(${scaleX}) scaleY(${scaleY})`;
//   }
//   refreshScale()
//   win.addEventListener("pageshow", function (e) {
//     if (e.persisted) { // 浏览器后退的时候重新计算
//       refreshScale()
//     }
//   }, false);
//   win.addEventListener("resize", refreshScale, false);
// })(window)
