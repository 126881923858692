import VueRouter from 'vue-router'
import store from '@/store/index'
import Layout from '@/components/layout/index'

const routes = [
  {
    path: '/',
    redirect: '/data/home',
  },
  {
    path: '/welcomeHome',
    name: 'WelcomeHome',
    component: () => import('@/views/home/welcomeHome.vue'),
    meta: {
      title: '暖师智友',
    },
  },
  {
    path: '/accountCenter',
    name: 'AccountCenter',
    component: () => import('@/views/accountCenter/index.vue'),
    meta: {
      title: '账号中心',
    },
  },
  {
    path: '/noPermission',
    name: 'NoPermission',
    component: () => import('@/views/noPermission/index.vue'),
    meta: {
      title: '无权限',
    },
  },
  {
    path: '/pending',
    name: 'Pending',
    component: () => import('@/views/pending/index.vue'),
    meta: {
      title: '待办事项',
    },
  },
  {
    path: '/districtYouthSoulHome',
    name: 'DistrictYouthSoulHome',
    component: () => import('@/views/districtYouthSoulHomeNew/index.vue'),
  },
  {
    path: '/districtYouthSoulHomeSchool',
    name: 'DistrictYouthSoulHomeSchool',
    component: () => import('@/views/districtYouthSoulHomeNew/school.vue'),
  },
  {
    path: '/districtYouthSoulHomeWarning',
    component: () => import('@/views/districtYouthSoulHomeNew/warning.vue'),
    name: 'districtYouthSoulHomeWarning',
    meta: {
      title: '暖师智友预警屏',
    },
  },
  {
    path: '/mindProfile',
    component: () => import('@/views/mindProfile/index.vue'),
    name: 'mindProfile',
    meta: {
      title: '暖师智友概况屏',
    },
  },
  {
    path: '/personalInformation',
    component: () => import('@/views/personalInformation/index.vue'),
    name: 'personalInformation',
    meta: {
      title: '暖师智友个人屏',
    },
  },
  {
    path: '/manageScreen',
    component: () => import('@/views/manageScreen/index.vue'),
    name: 'manageScreen',
    meta: {
      title: '暖师智友管理屏',
    },
  },
  {
    path: '/schoolScreen',
    component: () => import('@/views/schoolScreen/index.vue'),
    name: 'schoolScreen',
    meta: {
      title: '暖师智友学校屏',
    },
  },
  {
    path: '/districtScreen',
    component: () => import('@/views/warningScreen/districtScreen.vue'),
    name: 'districtScreen',
    meta: {
      title: '区域管理屏',
    },
  },
  {
    path: '/schoolWarningScreen',
    component: () => import('@/views/warningScreen/schoolScreen.vue'),
    name: 'schoolWarningScreen',
    meta: {
      title: '学校管理屏',
    },
  },
  {
    path: '/schoolWarning',
    component: () => import('@/views/warningScreen/schoolWarning.vue'),
    name: 'schoolWarning',
    meta: {
      title: '学校预警处理驾驶舱',
    },
  },
  {
    path: '/securityScreen',
    component: () => import('@/views/warningScreen/securityScreen.vue'),
    name: 'securityScreen',
    meta: {
      title: '安防预警处理驾驶舱',
    },
  },
  {
    path: '/data',
    component: Layout,
    redirect: '/data/homeVisitTaskConfig',
    meta: { title: '全员家访' },
    children: [
      {
        path: '/data/homeVisitTaskConfig',
        name: 'homeVisitTaskConfig',
        component: () => import('@/views/homeVisitTaskConfig/index.vue'),
        meta: { title: '全员家访' },
      },
      {
        path: '/data/templateHome',
        name: 'templateHome',
        component: () => import('@/views/homeVisitTaskConfig/templateHome.vue'),
        meta: { title: '全员家访' },
      },
      {
        path: '/data/home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页',
        },
      },
      {
        path: '/data/report',
        name: 'report',
        component: () => import('@/views/report/index.vue'),
        meta: {
          title: '数据报告',
        },
      },
      {
        path: '/data/securityWarning',
        component: () => import('@/views/securityWarning/index.vue'),
        name: 'securityWarning',
        meta: {
          title: '智能筛查',
        },
      },
      {
        path: '/data/trajectoryQuery',
        component: () => import('@/views/securityWarning/trajectoryQuery/index.vue'),
        name: 'trajectoryQuery',
        meta: {
          title: '轨迹查询',
        },
      },
      {
        path: '/data/pushConfig',
        component: () => import('@/views/securityWarning/pushConfig/index.vue'),
        name: 'pushConfig',
        meta: {
          title: '推送配置',
        },
      },
      {
        path: '/data/homeVisitRecord',
        component: () => import('@/views/homeVisitRecord/index.vue'),
        name: 'homeVisitRecord',
        meta: {
          title: '全员家访',
        },
      },
      {
        path: '/data/dailyDynamic',
        component: () => import('@/views/dailyDynamic/index.vue'),
        name: 'dailyDynamic',
        meta: {
          title: '日常动态观察',
        },
      },
      {
        path: '/data/attentionReminder',
        component: () => import('@/views/attentionReminder/index.vue'),
        name: 'attentionReminder',
        meta: {
          title: '重点关注',
        },
      },
      {
        path: '/data/interview',
        component: () => import('@/views/attentionReminder/interview/index.vue'),
        name: 'attentionReminder',
        meta: {
          title: '重点关注',
        },
      },
      {
        path: '/data/lastWarningLevel',
        component: () => import('@/views/attentionReminder/lastWarningLevel.vue'),
        name: 'lastWarningLevel',
        meta: {
          title: '最新预警等级',
        },
      },
      {
        path: '/data/studentDetail',
        component: () => import('@/views/attentionReminder/studentDetail.vue'),
        name: 'studentDetail',
        meta: {
          title: '重点关注',
        },
      },
      {
        path: '/data/progressMonitor',
        component: () => import('@/views/homeVisitRecord/progressMonitor.vue'),
        name: 'progressMonitor',
        meta: {
          title: '全员家访',
        },
      },
      {
        path: '/data/reminderConfiguration',
        component: () => import('@/views/reminderConfiguration/setting.vue'),
        name: 'reminderConfiguration',
        meta: {
          title: '提醒模型配置',
        },
      },
      {
        path: '/data/reminderConfigurationManagement',
        component: () => import('@/views/reminderConfiguration/management.vue'),
        name: 'reminderConfigurationManagement',
        meta: {
          title: '提醒模型配置管理',
        },
      },
      {
        path: '/data/groupApply',
        component: () => import('@/views/groupAssistant/groupApply.vue'),
        name: 'groupApply',
        meta: {
          title: '团辅申请',
        },
      },
      {
        path: '/data/groupRecord',
        component: () => import('@/views/groupAssistant/groupRecord.vue'),
        name: 'groupRecord',
        meta: {
          title: '团辅记录',
        },
      },

      {
        path: '/data/equipmentManage',
        component: () => import('@/views/equipmentManage/index.vue'),
        name: 'equipmentManage',
        meta: {
          title: '投入资金与设备',
        },
      },
      {
        path: '/data/mentalTeacherManage',
        component: () => import('@/views/mentalTeacherManage/index.vue'),
        name: 'mentalTeacherManage',
        meta: {
          title: '心理教师管理',
        },
      },
      {
        path: '/data/describeManage',
        component: () => import('@/views/describeManage/index.vue'),
        name: 'describeManage',
        meta: {
          title: '考试临近',
        },
      },
      {
        path: '/data/setback',
        component: () => import('@/views/describeManage/setback.vue'),
        name: 'setback',
        meta: {
          title: '动态反馈',
        },
      },
      {
        path: '/data/observationSchool',
        component: () => import('@/views/describeManage/observationSchool.vue'),
        name: 'observationSchool',
        meta: {
          title: '开学前后观察',
        },
      },
      {
        path: '/data/dailyObservationOfTeachers',
        component: () => import('@/views/describeManage/dailyObservationOfTeachers.vue'),
        name: 'dailyObservationOfTeachers',
        meta: {
          title: '教师日常动态观察',
        },
      },
      {
        path: '/data/studentFileDetails/:userId',
        component: () => import('@/views/studentFile/studentFileDetails.vue'),
        name: 'studentFileDetails',
        meta: {
          title: '学生档案详情',
        },
      },
      {
        path: '/data/growthRecord/:userId',
        component: () => import('@/views/growthRecord/index.vue'),
        name: 'growthRecord',
        meta: {
          title: '学生档案详情',
        },
      },
      {
        path: '/data/studentFile',
        component: () => import('@/views/studentFile/index.vue'),
        name: 'studentFile',
        meta: {
          title: '学生档案',
        },
      },
      {
        path: '/data/earlyWarningIntervention/:userId',
        component: () => import('@/views/studentFile/earlyWarningIntervention.vue'),
        name: 'earlyWarningIntervention',
        meta: {
          title: '预警干预详情',
        },
      },
      {
        path: '/data/scaleDetails/:userId',
        component: () => import('@/views/growthRecord/scaleDetails.vue'),
        name: 'scaleDetails',
        meta: {
          title: '量表详情',
        },
      },
      {
        path: '/data/scaleMeasurement',
        component: () => import('@/views/scaleMeasurement/index.vue'),
        name: 'scaleMeasurement',
        meta: {
          title: '学生测评',
        },
      },
      {
        path: '/data/scaleResult',
        component: () => import('@/views/scaleMeasurement/scaleResult.vue'),
        name: 'scaleResult',
        meta: {
          title: '量表测评',
        },
      },
      {
        path: '/data/scaleResultReport',
        component: () => import('@/views/scaleMeasurement/scaleResultReport.vue'),
        name: 'scaleResult',
        meta: {
          title: '量表测评',
        },
      },
      {
        path: '/data/systemScale',
        component: () => import('@/views/systemScale/index.vue'),
        name: 'systemScale',
        meta: {
          title: '学生测评',
        },
      },
      {
        path: '/data/otherEvaluation',
        component: () => import('@/views/otherEvaluation/index.vue'),
        name: 'otherEvaluation',
        meta: {
          title: '学生测评',
        },
      },
      {
        path: '/data/otherScaleResult',
        component: () => import('@/views/otherEvaluation/otherScaleResult.vue'),
        name: 'otherScaleResult',
        meta: {
          title: '他评结果',
        },
      },
      {
        path: '/data/FamilyInfo',
        component: () => import('@/views/familyInfo/index.vue'),
        name: 'FamilyInfo',
        meta: {
          title: '家庭信息',
        },
      },

      {
        path: '/data/scaleMeasurementTeacher',
        component: () => import('@/views/teacherEvaluation/index.vue'),
        name: 'scaleMeasurementTeacher',
        meta: {
          title: '教师测评',
        },
      },
      {
        path: '/data/systemScaleTeacher',
        component: () => import('@/views/teacherEvaluation/systemScale/index.vue'),
        name: 'systemScaleTeacher',
        meta: {
          title: '教师测评',
        },
      },
      {
        path: '/data/scaleResultTeacher',
        component: () => import('@/views/teacherEvaluation/scaleResult.vue'),
        name: 'scaleResultTeacher',
        meta: {
          title: '教师测评',
        },
      },
      {
        path: '/data/returnVisit',
        component: () => import('@/views/returnVisit/index.vue'),
        name: 'ReturnVisit',
        meta: {
          title: '回访干预',
        },
      },
      {
        path: '/data/intervene',
        component: () => import('@/views/returnVisit/intervene.vue'),
        name: 'Intervene',
        meta: {
          title: '智能干预',
        },
      },
      {
        path: '/data/AIScaleMeasurement',
        component: () => import('@/views/AIScaleMeasurement/index.vue'),
        name: 'AIScaleMeasurement',
        meta: {
          title: 'AI筛查',
        },
      },
      {
        path: '/data/AIScaleResult',
        component: () => import('@/views/AIScaleMeasurement/scaleResult.vue'),
        name: 'AIScaleResult',
        meta: {
          title: 'AI筛查',
        },
      },
      {
        path: '/data/AIScaleResultReport',
        component: () => import('@/views/AIScaleMeasurement/scaleResultReport.vue'),
        name: 'AIScaleResult',
        meta: {
          title: 'AI筛查',
        },
      },
      {
        path: '/data/AISystemScale',
        component: () => import('@/views/AIScaleMeasurement/AISystemScale.vue'),
        name: 'AISystemScale',
        meta: {
          title: 'AI筛查',
        },
      },
      {
        path: '/data/downloadCenter',
        component: () => import('@/views/downloadCenter/index.vue'),
        name: 'DownloadCenter',
        meta: {
          title: '下载中心',
        },
      },
      {
        path: '/data/dataDocking',
        component: () => import('@/views/dataDocking/index.vue'),
        name: 'DataDocking',
        meta: {
          title: '省平台数据对接',
        },
      },
      {
        path: '/data/upstreamRecord',
        component: () => import('@/views/dataDocking/upstreamRecord/index.vue'),
        name: 'UpstreamRecord',
        meta: {
          title: '省平台数据对接',
        },
      },
      {
        path: '/data/emotionRecord',
        component: () => import('@/views/emotion/index.vue'),
        name: 'EmotionRecord',
        meta: {
          title: '情绪日志',
        },
      },
      {
        path: '/data/faceUpload',
        name: 'FaceUpload',
        component: () => import('@/views/studentInfo/index.vue'),
        meta: {
          title: '人像上传',
        },
      },
      {
        path: '/data/appointmentRecordQuery',
        component: () => import('@/views/appointmentRecordQuery/index.vue'),
        name: 'AppointmentRecordQuery',
        meta: {
          title: '预约记录',
        },
      },
      {
        path: '/data/aiSetting',
        name: 'aiSetting',
        component: () => import('@/views/aiSetting/index.vue'),
        meta: {
          title: 'AI设置',
        },
      },
      {
        path: '/data/AIReport',
        name: 'AIReport',
        component: () => import('@/views/aiSetting/aiReport.vue'),
        meta: {
          title: '聊天报告',
        },
      },
      {
        path: '/data/signature',
        name: 'signature',
        component: () => import('@/views/signature/index.vue'),
        meta: {
          title: '在线签字',
        },
      },
      {
        path: '/data/activityManage',
        component: () => import('@/views/activityManage/index.vue'),
        name: 'activityManage',
        meta: {
          title: '活动管理',
        },
      },
      {
        path: '/data/activityBmDetail',
        component: () => import('@/views/activityManage/bmDetail.vue'),
        name: 'activityBmDetail',
        meta: {
          title: '活动报名详情',
        },
      },
      {
        path: '/data/tutorChoice',
        component: () => import('@/views/tutorChoice/index.vue'),
        name: 'tutorChoice',
        meta: {
          title: '教师自选管理',
        },
      },
      {
        path: '/data/taskSelectProgress',
        component: () => import('@/views/tutorChoice/taskSelectProgress.vue'),
        name: 'taskSelectProgress',
        meta: {
          title: '教师自选管理',
        },
      },
      {
        path: '/data/customScale',
        component: () => import('@/views/customScale/index.vue'),
        name: 'customScale',
        meta: {
          title: '自定义量表',
        },
      },
      {
        path: '/data/customEditScale',
        component: () => import('@/views/customScale/editScale.vue'),
        name: 'customEditScale',
        meta: {
          title: '自定义量表',
        },
      },
      {
        path: '/data/campusPsychology',
        component: () => import('@/views/campusPsychology/index.vue'),
        name: 'taskSelectProgress',
        meta: {
          title: '校园心理数据看板',
        },
      },
      {
        path: '/data/campusPsychologyDetail',
        component: () => import('@/views/campusPsychology/userDetail.vue'),
        name: 'campusPsychologyDetail',
        meta: {
          title: '情绪趋势分析',
        },
      },
      {
        path: '/data/allStudent',
        component: () => import('@/views/campusPsychology/allStudent.vue'),
        name: 'allStudent',
        meta: {
          title: '学生情绪模型',
        },
      },
      {
        path: '/data/accountBindManage',
        component: () => import('@/views/accountBindManage/index.vue'),
        name: 'accountBindManage',
        meta: {
          title: '账号绑定配置',
        },
      },
      {
        path: '/data/moodJournal',
        component: () => import('@/views/campusPsychology/allPic.vue'),
        name: 'moodJournal',
        meta: {
          title: '心情日志',
        },
      },
      {
        path: '/data/classEmotion',
        component: () => import('@/views/campusPsychology/allClass.vue'),
        name: 'classEmotion',
        meta: {
          title: '校园情绪明细',
        },
      },
      {
        path: '/data/classEmotionDetail',
        component: () => import('@/views/campusPsychology/classDetail.vue'),
        name: 'classEmotionDetail',
        meta: {
          title: '班级情绪明细',
        },
      },
      {
        path: '/data/behaviorJournal',
        component: () => import('@/views/attentionReminder/behaviorJournal.vue'),
        name: 'behaviorJournal',
        meta: {
          title: '行为日报',
        },
      },
      {
        path: '/data/hk',
        component: () => import('@/views/hk/index.vue'),
        name: 'hk',
        meta: {
          title: '海康大屏',
        },
      },
      {
        path: '/data/grp',
        component: () => import('@/views/allReport/index.vue'),
        name: 'grp',
        meta: {
          title: '个人报告',
        },
      },
      {
        path: '/data/xs',
        component: () => import('@/views/allReport/school.vue'),
        name: 'xs',
        meta: {
          title: '学校报告',
        },
      },
      {
        path: '/data/qy',
        component: () => import('@/views/allReport/qy.vue'),
        name: 'qy',
        meta: {
          title: '区域报告',
        },
      },
      {
        path: '/data/qyList',
        component: () => import('@/views/allReport/indexList.vue'),
        name: 'qyList',
        meta: {
          title: '区域报告',
        },
      },
      {
        path: '/data/studentReport',
        component: () => import('@/views/allReport/studentList.vue'),
        name: 'studentReport',
        meta: {
          title: '区域报告',
        },
      },
    ],
  },
]

import { menuArr } from './tabMenu'
import { toAuth } from './authoriza.js'
import { getToken, getRoleId, setRoleId, getUserLogin } from '@/utils/auth'
import { permsCurrent } from '@/api/schoolScreen'
import * as API from '@/api/app.js'

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/jdp/',
  // base: '/',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
})

const whiteRoutes = ['WelcomeHome', 'schoolScreen', 'personalInformation']
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 设置顶部tab
  let arr = menuArr.find((item) => {
    if (item.name.includes(to.name)) {
      store.commit('setMenuItem', item.list)
      return item
    }
  })
  console.log(arr)
  if (!arr) {
    store.commit('setMenuItem', [])
  }

  if (whiteRoutes.indexOf(to.name) > -1) {
    next()
    return
  }
  // 获取用户信息
  const userInfo = getUserLogin()
  if (!userInfo) {
    if (process.env.VUE_APP_IS_CERTIFICATION != 'false') {
      toAuth(to, from, next)
    }
  } else if (userInfo && (!userInfo.identityId || userInfo.identityId === 5)) {
    /**
     * 未实名认证的需用需要拦截到“个人中心页面”
     * 需要满足 bound initial 两个属性有一个为false既表示未认证
     */
    // 拦到账号中心页面
    to.name === 'AccountCenter' ? next() : next({ name: 'AccountCenter' })
  } else {
    let roleId = getRoleId()
    if (!roleId) {
      const res1 = await API.getUserRoleInfo()
      if (res1.data?.roleList[0]?.roleId) {
        setRoleId(res1.data.roleList[0].roleId)
      }
    }
    next()
  }
  // Remove 'token' parameter from the URL
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('token')) {
    // Create a new URL without any parameters
    const newUrl = `${window.location.pathname}`
    window.history.replaceState({}, document.title, newUrl)
  }
})
export const getPermissions = async () => {
  try {
    const permissionList = JSON.parse(localStorage.getItem('permissionList')) || []
  } catch (err) {
    console.log('[ err ] >', err)
  }
}
export default router
